<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form label-width="80px" @submit.native.prevent>
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：出仓单一览</strong>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :lg="24">
                            <el-row>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-date-picker
                                            style=" margin-top: 8px; width: 220px"
                                            v-model="pickerDate"
                                            type="daterange"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            align="right">
                                    </el-date-picker>
                                </el-col>

                                <el-col :lg="8" :sm="12" :md="8">
                                    <el-button style=" margin-top: 8px; " id="createBill" type="primary" @click="searchAllWarehouse" round  size="mini">搜索查询</el-button>
                                    <el-button v-if="$_getActionKeyListBoolean(['/outWarehouse'])"
                                               style=" margin-top: 8px; " type="primary" round
                                               :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                               @click="openOutWarehouse($refs.xGrid.getCurrentRecord())" size="mini">修改
                                    </el-button>
                                    <el-button type="primary" size="mini" @click="$router.back()"
                                               style="margin-bottom: 2px;margin-top: 2px">返回
                                    </el-button>
                                    <el-button type="success" v-if="$_getActionKeyListBoolean(['/outWarehouse'])" style=" margin-top: 8px; " round
                                               @click="$router.push('/outWarehouse')" size="mini">新建
                                    </el-button>
                                    <el-button v-if="$_getActionKeyListBoolean(['/transferSlip/setIsBillCancel'])"
                                               style=" margin-top: 8px; " type="danger" round
                                               :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null || this.$refs.xGrid.getCurrentRecord().is_cancel_name == '作废'"
                                               @click="setIsBillCancel($refs.xGrid.getCurrentRecord())" size="mini">作废
                                    </el-button>
                                    <el-checkbox style="padding-left: 7px" v-model="formData.showCancel"
                                                 @change="getAllWarehouse()">显示作废
                                    </el-checkbox>
                                </el-col>
                                <el-col :lg="6" :sm="12" :md="8">
                                    <el-row>
                                        <el-col class="pull-left" :lg="4">
                                            <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export ref="xToolbar2">
                                            </vxe-toolbar>
                                        </el-col>

                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>

                    </el-row>
<el-row>
    <el-col :lg="24">
        <vxe-grid
                  border
                  resizable
                  show-overflow
                  keep-source
                  ref="xGrid"
                  align="center"
                  size="mini"
                  :height="(this.getViewHeight() - 130)+''"
                  highlight-current-row
                  :row-class-name="tableRowClassName"
                  show-footer
                  :footer-method="footerMethod"
                  :print-config="{}"
                  :data="tableDataMain"
                  :columns="tableColumn"
                  :mouse-config="{selected: false}"
                  :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                  :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false,editMethod}">
            <template #operate="{row}">
                <vxe-button icon="el-icon-view" title="查看" circle @click="openOutWarehouse(row)" ></vxe-button>
            </template>
            <template #pager>
                <vxe-pager
                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                        :current-page.sync="tablePage.currentPage"
                        :page-size.sync="tablePage.pageSize"
                        :total="tablePage.total"
                        @page-change="handlePageChange">
                </vxe-pager>
            </template>
        </vxe-grid>
    </el-col>


</el-row>

                </el-form>
            </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                pickerDate: [new Date(new Date().getTime()- 3600 * 1000 * 24 * 7), new Date()],
                formData:{
                    showCancel: false,
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(), new Date()]);
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [date,date]);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [date,date]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '最近365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }]
               },
                tableColumn: [
                    {title: '序号', type: 'seq', width: 40},
                    {
                        field:'is_cancel_name', width: 60, title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {field:'bill_type', width: 100 ,title: '类型' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {field:'print_count', width: 55 ,title: '打印' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{field:'only_bill_id', width: 160 ,title: '单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{field:'only_bill_date', width: 120 ,title: '日期',formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')} ,slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{field:'warehouse_out', width: 100 ,title: '调出仓库' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{field:'category', width: 65 ,title: '类别' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'specification', width: 150 ,title: '实际规格' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'single_count', width: 80 ,title: '支数' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    { field:'weighing_heavy', width: 100 ,title: '过磅重(吨)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    { field:'amount_of_product', width: 100 ,title: '产品金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    { field:'practical_price', width: 100 ,title: '实收金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    { field:'actual_sales_amount', width: 100 ,title: '实际销售金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    { field:'naked_price', width: 100 ,title: '产品裸价' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    { field:'warehouse', width: 100 ,title: '调入仓库' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'handled_by', width: 70 ,title: '经手人' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'registrar', width: 70 ,title: '记录员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'creat_date_warehouse_bill', width: 150 ,title: '创建日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'b_id', width: 160, visible: false, title: '单据唯一Id', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                ],
                tableDataMain : [],
                filterData: {
                    "bill_id_type":"CC",
               },
                sortData: {"creat_date_warehouse_bill":"desc"},
           }
       },
        methods: {
            searchAllWarehouse(){
                this.tablePage.currentPage = 1;
                this.getAllWarehouse();
           },
            getAllWarehouse(){
                this.$axios({
                    method:'POST',
                    url:'/admin/allWarehouse/search',
                    data:{
                        currentPage: this.tablePage.currentPage,
                        pageSize:this.tablePage.pageSize,
                        sortData:this.sortData,
                        filterData:this.filterData,
                        pickerDate:this.pickerDate,
                        formData: this.formData
                   }
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) =>{
                    console.log(error)
               });

           },
            handlePageChange ({ currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                //this.findList()
           },
            roleFetchSuggestions (queryString, cb) {
                var restaurants = this.restaurants
                var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    cb(results)
               }, 1000 )
           },
            editMethod ({ row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate (value, format) {
                return XEUtils.toDateString(value, format)
           },
            openOutWarehouse(row){
                if(row.bill_id_type == 'HC'){
                    const{ href} = this.$router.resolve({path: '/replaceOutWarehouse', query: {bill_id: row.bill_id}});
                    window.open(href,'_blank');
                    //this.$router.push({ path: '/replaceOutWarehouse', query: { bill_id: row.bill_id}})
               }else if(row.bill_id_type == 'JG'){
                    const{ href} = this.$router.resolve({path: '/machining', query: {bill_id: row.bill_id,wb_id: row.b_id}});
                    window.open(href,'_blank');
                    //this.$router.push({ path: '/machining', query: { bill_id: row.bill_id,wb_id: row.b_id}})
               }else if(row.bill_id_type == 'KD'){
                    const{ href} = this.$router.resolve({path: '/transferSlip', query: {bill_id_kd : row.only_bill_id}});
                    window.open(href,'_blank');
                    //this.$router.push({ path: '/machining', query: { bill_id: row.bill_id,wb_id: row.b_id}})
                }else if(row.bill_id_type == 'CC'){
                    const{ href} = this.$router.resolve({path: '/outWarehouse', query: {bill_id : row.only_bill_id}});
                    window.open(href,'_blank');
                    //this.$router.push({ path: '/machining', query: { bill_id: row.bill_id,wb_id: row.b_id}})
                }else{
                    const{ href} = this.$router.resolve({path: '/intoTheWarehouse', query: {bill_id: row.bill_id,wb_id: row.b_id}});
                    window.open(href,'_blank');
                    //this.$router.push({ path: '/outWarehouse', query: { bill_id: row.bill_id}})
               }
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count ? this.$XEUtils.round(count, 2) : 0
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property == 'weighing_heavy' || column.property == 'naked_price'
                            || column.property == 'amount_of_product'|| column.property == 'practical_price'
                            || column.property == 'actual_sales_amount'
                            ) {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                       }else if (column.property == 'single_count') {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
       },
        created () {
            this.getAllWarehouse();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
/*    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }*/

    /deep/ .el-input--suffix .el-input__inner{
        padding-right: initial!important;
   }

    /deep/ input[popperclass="myAutocomplete"]{
        padding:3px!important;
   }
    /deep/ .el-input__suffix {
        right: 0px!important;
   }
</style>
